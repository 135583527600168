<template>
  <div class="product-component" v-if="categories">
    <div v-for="(item, i) in categories" :key="i">
      <div class="product-component-title">
        <div class="container">
          <h2 class="section-title">{{item.name}}</h2>
        </div>
      </div>
      <div class="product-component-section">
        <div class="container">
          <div class="product-component-section-block" v-for="(subcategory, index) in item.subcategory" :key="index">
            <div class="product-component-section-left">
              <h3 class="text-section">{{ subcategory }}</h3>
            </div>
            <div class="product-component-section-right">
              <div class="product-component-section-right-item">
                <router-link :to="{path: '/card'}">
                  <div>
                    <img src="../assets/images/img/tv-home.png" alt="img">
                  </div>
                  <p>Product Name</p>
                </router-link>
              </div>
              <div class="product-component-section-right-item">
                <router-link :to="{path: '/card'}">
                  <div>
                    <img src="../assets/images/img/tv-home.png" alt="img">
                  </div>
                  <p>Product Name</p>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProductComponent",
  props: ['categories']
}
</script>

<style lang="scss">
@import "@/assets/scss/_vars.scss";
.product-component{
  background-color: $white;
  .product-component-title{
    background-color: rgba(164, 163, 163, 0.9);
    .section-title{
      padding: 9px 0 9px 40px;
      color: $white;
    }
  }
  .product-component-section{
    padding: 54px 0 87px;
    .container{
      .product-component-section-block{
        width: 100%;
        display: flex;
        justify-content: space-between;
        &:first-child{
          margin-top: 0;
        }
        .product-component-section-left{
          .text-section{
            margin-top: 10px;
            font-weight: 600;
            color: $homeTextBlack;
          }
        }
        .product-component-section-right{
          max-width: 1015px;
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          .product-component-section-right-item{
            max-width: 325px;
            width: 100%;
            & > a{
              margin-right: 20px;
              & > div{
                width: 100%;
                height: 182px;
                display: flex;
                justify-content: center;
                & > img{
                  max-width: 100%;
                }
              }
              & > p{
                font-style: normal;
                font-weight: 500;
                font-size: 20px;
                line-height: 26px;
                text-align: center;
                letter-spacing: 0.16px;
                color: #3A3A3A;
              }
              &:last-child{
                margin-right: 0;
              }
            }
          }
        }
      }
    }
  }
  &:nth-child(2){
    padding-top: 49px;
  }
}

</style>
