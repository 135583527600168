<template>
<div class="wearables">
  <div
      class="wearables-img"
      :style="bgImg"
  >
    <h2
        class="title"
        data-aos="fade-up"
        data-aos-offset="200"
        data-aos-delay="50"
        data-aos-duration="500"
    >{{title}}</h2>
  </div>
  <ProductComponent
      :categories="categoryData"
      v-if="title === 'Wearables'"
  />
  <SubComponentProduct
      v-if="title !== ''"
      :categories="subProductData"
  />
  <Footer/>
</div>
</template>

<script>
import Footer from "@/components/Nav/Footer";
import ProductComponent from "@/components/ProductComponent";
import SubComponentProduct from "@/components/SubComponentProduct";
export default {
  name: "Wearables",
  components: {SubComponentProduct, ProductComponent, Footer},
  data(){
    return{
      categoryData: [
        {
          name: 'Smart Watch',
          subcategory: ['Smart Watches', 'Smart Health Monitors', 'Fitness & Activity trackers']
        },
        {
          name: 'Smart Watch Pro',
          subcategory: ['Smart Watches', 'Smart Health Monitors', 'Fitness & Activity trackers']
        },
        {
          name: 'Smart Braclet',
          subcategory: ['Smart Watches', 'Smart Health Monitors', 'Fitness & Activity trackers']
        },
      ],
      bgImg: {
        backgroundImage: `url(${require('@/assets/images/img/wearables.png')})`
      },
      title: 'Wearables',
      subProductData: [],
    }
  },
  computed: {

  },
  watch:{

  },
  mounted() {
    window.scrollTo(0,0)

    this.title =  this.subcategory_title || this.title
  },
  methods: {

  },
}
</script>

<style lang="scss">
@import "@/assets/scss/_vars.scss";
.wearables{
  .wearables-img{
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    background-position: top center;
    padding: 414px 0 77px 100px;
    .title{
      color: $white;
    }
  }
}
</style>
